import React from 'react'
import Layout from '../../components/layout'
import { ProjectHeader } from '../../components/projects'
import ProjectCTA from '../../components/project-cta'

import Image from '../../images/projects/project-main-eras.jpg'

import Image1 from '../../images/projects/eras/mac.jpg'
import Image2 from '../../images/projects/eras/illustration-1.svg'
import Image21 from '../../images/projects/eras/illustration-1.1.svg'
import Image3 from '../../images/projects/eras/illustration-2.svg'

import './eras.less'

class Eras extends React.Component {
  render() {
    return (
      <Layout>
        <ProjectHeader
          title="ERAS Cardiac"
          year="2018"
          index="2"
          image={Image}
          total="3"
          overview="The Society for Enhanced Recovery After Cardiac Surgery (ERAS® Cardiac) is on a mission to optimize the care and recovery of cardiac surgical patients through the research, education, audit, and implementation of evidence-based practices."
          expertise="Design and Development"
          url="https://www.erascardiac.org"
          prev="/featured-projects/workplace-one"
          next="/featured-projects/everyday-icing"
        />

        <section className="project-single-body eras" id="start_dark_menu">
          <div className="uk-container">
            <div className="uk-grid">
              <div className="uk-width-5-6@l uk-margin-auto">
                <img src={Image1} alt="" className="mac" />
              </div>
              <div className="uk-width-1-2@l uk-width-2-3@m uk-width-5-6@s uk-margin-auto">
                <h2>A society rooted in better patient recovery.</h2>
                <p>
                  Our focus was to tell the story of the ERAS® Cardiac
                  community, demonstrate how this team is a catalyst in its
                  mission, and to underscore the size of their reach.
                  <br />
                  <br />
                  We redesigned the ERAS® Cardiac website to reflect their roots
                  as a knowledge-sharing society, and their vision for bettering
                  the future of healthcare delivery.
                  <br />
                  <br />
                  To do this, we helped architect a system to link visitors
                  directly to news, events, and other educational resources
                  where they can become further involved.
                </p>
              </div>
              <div className="uk-width-5-6@l uk-margin-auto">
                <div className="eras-animated-image">
                  <img src={Image2} alt="" className="static-image" />
                  <img src={Image21} alt="" className="rotating-image" />
                </div>
              </div>
              <div className="uk-width-1-2@l uk-width-2-3@m uk-width-5-6@s uk-margin-auto">
                <h2>Helping them help people.</h2>
                <p>
                  Our strategy was to create an engaging desktop experience that
                  included a seamless tablet and mobile transition.
                  <br />
                  <br />
                  We revised several iterations of the visual layout before
                  landing on one that would provide easy navigation, visual
                  storytelling, and serve users with the exact information they
                  came looking for.
                  <br />
                  <br />
                  The result is a look and feel that not only welcomes visitors
                  into the community but also highlights ERAS® Cardiac’s passion
                  for bettering the quality of healthcare improvement processes.
                </p>
              </div>
              <div className="uk-width-1-1">
                <img src={Image3} alt="" className="last-image" />
              </div>
            </div>
          </div>
        </section>

        <ProjectCTA link="everyday-icing" title="Everyday Icing" />
      </Layout>
    )
  }
}

export default Eras
